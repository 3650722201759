exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adm-treat-info-tsx": () => import("./../../../src/pages/adm-treat-info.tsx" /* webpackChunkName: "component---src-pages-adm-treat-info-tsx" */),
  "component---src-pages-cognitive-treat-tsx": () => import("./../../../src/pages/cognitive-treat.tsx" /* webpackChunkName: "component---src-pages-cognitive-treat-tsx" */),
  "component---src-pages-cooperation-tsx": () => import("./../../../src/pages/cooperation.tsx" /* webpackChunkName: "component---src-pages-cooperation-tsx" */),
  "component---src-pages-counsel-visit-info-tsx": () => import("./../../../src/pages/counsel-visit-info.tsx" /* webpackChunkName: "component---src-pages-counsel-visit-info-tsx" */),
  "component---src-pages-facilities-tsx": () => import("./../../../src/pages/facilities.tsx" /* webpackChunkName: "component---src-pages-facilities-tsx" */),
  "component---src-pages-greeting-tsx": () => import("./../../../src/pages/greeting.tsx" /* webpackChunkName: "component---src-pages-greeting-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-korean-medical-tsx": () => import("./../../../src/pages/korean-medical.tsx" /* webpackChunkName: "component---src-pages-korean-medical-tsx" */),
  "component---src-pages-mission-vision-tsx": () => import("./../../../src/pages/mission-vision.tsx" /* webpackChunkName: "component---src-pages-mission-vision-tsx" */),
  "component---src-pages-non-insurance-tsx": () => import("./../../../src/pages/non-insurance.tsx" /* webpackChunkName: "component---src-pages-non-insurance-tsx" */),
  "component---src-pages-notices-면회-공지-index-mdx": () => import("./../../../src/pages/notices/면회 공지/index.mdx" /* webpackChunkName: "component---src-pages-notices-면회-공지-index-mdx" */),
  "component---src-pages-notices-방문-감사합니다-index-mdx": () => import("./../../../src/pages/notices/방문 감사합니다/index.mdx" /* webpackChunkName: "component---src-pages-notices-방문-감사합니다-index-mdx" */),
  "component---src-pages-notices-변경된-면회-공지-index-mdx": () => import("./../../../src/pages/notices/변경된 면회 공지/index.mdx" /* webpackChunkName: "component---src-pages-notices-변경된-면회-공지-index-mdx" */),
  "component---src-pages-notices-접촉-면회-재개-안내-index-mdx": () => import("./../../../src/pages/notices/접촉 면회 재개 안내/index.mdx" /* webpackChunkName: "component---src-pages-notices-접촉-면회-재개-안내-index-mdx" */),
  "component---src-pages-notices-접촉-면회-중단-안내-index-mdx": () => import("./../../../src/pages/notices/접촉 면회 중단 안내/index.mdx" /* webpackChunkName: "component---src-pages-notices-접촉-면회-중단-안내-index-mdx" */),
  "component---src-pages-patient-rights-duties-tsx": () => import("./../../../src/pages/patient-rights-duties.tsx" /* webpackChunkName: "component---src-pages-patient-rights-duties-tsx" */),
  "component---src-pages-physical-therapy-tsx": () => import("./../../../src/pages/physical-therapy.tsx" /* webpackChunkName: "component---src-pages-physical-therapy-tsx" */),
  "component---src-pages-social-welfare-tsx": () => import("./../../../src/pages/social-welfare.tsx" /* webpackChunkName: "component---src-pages-social-welfare-tsx" */),
  "component---src-pages-treat-system-tsx": () => import("./../../../src/pages/treat-system.tsx" /* webpackChunkName: "component---src-pages-treat-system-tsx" */),
  "component---src-pages-way-to-come-tsx": () => import("./../../../src/pages/way-to-come.tsx" /* webpackChunkName: "component---src-pages-way-to-come-tsx" */),
  "component---src-templates-notice-tsx": () => import("./../../../src/templates/notice.tsx" /* webpackChunkName: "component---src-templates-notice-tsx" */),
  "component---src-templates-notices-tsx": () => import("./../../../src/templates/notices.tsx" /* webpackChunkName: "component---src-templates-notices-tsx" */)
}

